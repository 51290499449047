import React from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { CallbackStyles as styles } from "../../styles";
import auth from "../../authentication/service";
import LogoLoader from "../../components/LogoLoader";

export class Callback extends React.PureComponent {
  state = {
    loading: true
  };

  async componentDidMount() {
    await auth.handleAuthentication();
    setTimeout(() => {
      this.setState({ loading: false });
    }, 10000);
  }

  renderContent() {
    if (this.state.loading) {
      return (
        <div style={styles.loader}>
          <LogoLoader />
        </div>
      );
    }
    return (
      <div style={styles.container}>
        <LogoLoader />
        <div style={{ marginTop: "10px", marginBottom: "35px" }}>
          <h2 style={styles.title}>Access denied</h2>
          <p style={styles.text}>Please contact the administrator.</p>
        </div>
        <Link to="/">
          <Button style={styles.button}>Login</Button>
        </Link>
      </div>
    );
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

export default Callback;
