import createAuth0Client from "@auth0/auth0-spa-js";
import history from "../history";
import member from "../models/team-member";
import { turnAPI } from "../services";

class Auth {
  auth0 = null;

  constructor() {
    // Listen for history changes
    this.unlisten = history.listen(this.handleLocationChange);
  }

  init = async () => {
    this.auth0 = await createAuth0Client({
      domain: process.env.REACT_APP_AUTH_DOMAIN,
      client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
      redirect_uri: `${window.location.origin}/callback`,
      audience: "https://turning.io/partner-dashboard",
      response_type: "token id_token",
      scope: "openid profile email",
      cacheLocation: "localstorage"
    });
  };

  handleLocationChange = () => {
    const currentPath = window.location.pathname;

    if (["/login"].includes(currentPath)) return;

    if (currentPath !== localStorage.getItem("last_path")) {
      localStorage.setItem("last_path", currentPath);
    }
  };

  handleAuthentication = async () => {
    if (!this.auth0) await this.init();
    try {
      await this.auth0.handleRedirectCallback();
      const accessToken = await this.auth0.getTokenSilently();
      const profile = await this.auth0.getIdTokenClaims();

      if (accessToken === undefined) {
        this.handleUnAuthorized(401);
        return;
      }
      const response = await turnAPI.setToken(accessToken).getMember();
      if (accessToken && profile.__raw) {
        switch (response.status) {
          case 200:
            this.handleAuthorized({
              accessToken,
              profile,
              data: response.data
            });
            break;
          case 401:
            this.handleUnAuthorized(401);
            break;
          default:
            this.handleUnAuthorized(500);
            break;
        }
      }
    } catch (error) {
      this.handleUnAuthorized(401);
    }
  };

  handleUnAuthorized = () => {
    this.login();
  };

  handleAuthorized = ({ accessToken, profile, data }) => {
    const d = data;
    d.email = profile.email;
    d.user_name = d.team_member.first_name || profile.user_name;
    d.partner_id = data.uuid;
    d.member_id = data.member_id;
    d.profile_picture = data.team_member.profile_picture;
    d.role_permissions = data.team_member.role_permissions;
    localStorage.setItem("id_token", profile.__raw);
    member.setMember(d);
    localStorage.setItem("profile", JSON.stringify(profile));
    member.saveMember();
    this.setToken(accessToken);
    this.setLastPath();
  };

  login = async () => {
    if (!this.auth0) await this.init();
    this.auth0.loginWithRedirect();
  };

  loggedIn = async () => {
    this.handleLocationChange();
    if (!this.auth0) await this.init();
    return await this.auth0.isAuthenticated();
  };

  setToken = accessToken => {
    localStorage.setItem("access_token", accessToken);
  };

  getToken = () => localStorage.getItem("access_token");

  getProfile = () => JSON.parse(localStorage.getItem("profile"));

  permission = () => {
    history.replace("/permission");
  };

  logout = async () => {
    if (!this.auth0) await this.init();
    this.auth0.logout({ localOnly: true });
    member.removeMember();
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("profile");
    history.replace("/login");
  };

  setLastPath = () => {
    const lastPath = localStorage.getItem("last_path");
    window.location.assign(lastPath || "/screen");
  };

  // Cleanup the listener when instance is destroyed
  destroy() {
    if (this.unlisten) {
      this.unlisten(); // Unsubscribe from the history listener
    }
  }
}

export default Auth;
