import { secondary, white } from "../constants";

const styles = {
  button: {
    width: "200px",
    textTransform: "capitalize",
    fontSize: "16px",
    backgroundColor: secondary,
    color: white
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    textAlign: "center",
    fontFamily: "Inter"
  },
  loader: {
    zIndex: "10",
    opacity: "1",
    position: "absolute",
    width: "100%",
    left: "0",
    top: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: "#f7f9fc87"
  },
  text: {
    margin: "0",
    fontSize: "14px",
    color: "#7E7E8F"
  },
  title: {
    margin: "0",
    fontSize: "22px"
  }
};
export default styles;
